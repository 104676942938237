"use client";

import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import ReactLoading from "react-loading";
import { useTranslation } from "next-i18next";
import styles from "../../../styles/horizontalScroll.module.css";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PauseIcon from "@mui/icons-material/Pause";
import ReplayIcon from "@mui/icons-material/Replay";

export default function LivePricing() {
  const { t } = useTranslation("common");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  const calculateRepetitions = useCallback(() => {
    if (typeof window === "undefined" || !data.length) return 3;
    const itemWidth = 200 + 24; // Width + gap
    const viewportItems = Math.ceil(window.innerWidth / itemWidth);
    return Math.max(3, viewportItems * 2);
  }, [data]);

  const generateItems = useCallback(() => {
    const reps = calculateRepetitions();
    return Array(reps)
      .fill()
      .flatMap((_, i) =>
        data.map((token, j) => ({
          ...token,
          uid: `${token.baseToken}-${i}-${j}`,
        }))
      );
  }, [data, calculateRepetitions]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "https://coimex.net/api/v1/blockchain/tokens/prices/USDT"
        );
        setData(res.data.data.filter((item) => item !== null));
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (data.length) {
      setItems(generateItems());

      // Animation sync hack
      requestAnimationFrame(() => {
        const track = document.querySelector(`.${styles.tickerTrack}`);
        if (track) {
          track.style.animation = "none";
          requestAnimationFrame(() => {
            track.style.animation = "";
          });
        }
      });
    }
  }, [data, generateItems]);

  return (
    <div className={styles.tickerContainer}>
      {loading ? (
        <div className="flex justify-center py-2">
          <ReactLoading type="bars" color="#1A73E9" height={24} />
        </div>
      ) : data.length ? (
        <div
          className={styles.tickerTrack}
          style={{ "--scroll-speed": `${data.length * 50}s` }}
        >
          {items.map((token) => (
            <div key={token.uid} className={styles.tickerItem}>
              <span className="text-[#313033]">{token.baseToken}</span>
              <span className="mx-2 text-[#1A73E9]">${token.price}</span>
              {token.changePercentage >= 0 ? (
                <span className="text-[#4C9C18] flex items-center">
                  <ArrowDropUpIcon />
                  {Math.abs(token.changePercentage)}%
                </span>
              ) : (
                <span className="text-[#B00020] flex items-center">
                  <ArrowDropDownIcon />
                  {Math.abs(token.changePercentage)}%
                </span>
              )}
            </div>
       
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center text-red-600 py-2">
          Failed to load data
          <ReplayIcon
            className="text-blue-600 ml-2 cursor-pointer"
            onClick={() => {
              setLoading(true);
              fetchData();
            }}
          />
        </div>
      )}
    </div>
  );
}
