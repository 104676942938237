"use client";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactLoading from "react-loading";
import { useTranslation } from "next-i18next";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ReplayIcon from "@mui/icons-material/Replay";

export const LivePrice = () => {
  const { t } = useTranslation("common");
  const [cryptoData, setCryptoData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCryptoData = async () => {
    try {
      // Fetch data from the API
      const response = await axios.get(
        "https://coimex.net/api/v1/blockchain/tokens/prices/USDT"
      );

      const filteredData = response.data.data.filter((item) => item !== null);

      // Set the filtered data to state
      setCryptoData(filteredData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cryptocurrency data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data immediately and every 60 seconds
    fetchCryptoData();
    const interval = setInterval(fetchCryptoData, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="flex bg-[#d7dae3]
  pt-2 pb-2  justify-start  w-full gap-y-2 items-center overflow-x-auto"
    >
      <div className="inner h-[48px] flex items-center justify-center gap-x-2">
        {loading ? (
          <div className="flex items-center justify-center pt-2 pb-2">
            <ReactLoading
              type="bars"
              color="#1A73E9"
              height={24}
              className="flex items-center justify-center"
            />
          </div>
        ) : cryptoData.length > 0 ? (
          <div className="wrapper gap-[50px] overflow-hidden pl-10">
            <section
              className="section whitespace-nowrap"
              style={{ "--speed": `${20000}ms` }}
            >
              {cryptoData.map((token, i) => (
                <div
                  key={i}
                  className="items-center inline-block !w-[200px] md:!w-[285px]"
                >
                  <span className="mr-2 md:mr-4 text-[#313033] font-montserrat text-base">
                    {token.baseToken}
                  </span>
                  <span className="text-[#1A73E9] mr-2 md:mr-4 font-montserrat text-base">
                    ${token.price}
                  </span>
                  {token.changePercentage >= 0 ? (
                    <ArrowDropUpIcon
                      className="text-[#4C9C18] text-[24px]"
                      title="Increase"
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className="text-red-600 text-[24px]"
                      title="Decrease"
                    />
                  )}
                  {token.changePercentage >= 0 ? (
                    <span className="text-[#4C9C18] font-montserrat text-base mr-2 md:mr-4">
                      {token.changePercentage}%
                    </span>
                  ) : token.changePercentage < 0 ? (
                    <span className="text-red-600 font-montserrat text-base mr-2 md:mr-4">
                      {token.changePercentage}%
                    </span>
                  ) : (
                    <span className="text-[#757575] font-montserrat text-base mr-2 md:mr-4">
                      {token.changePercentage}%
                    </span>
                  )}
                </div>
              ))}
            </section>
            <section
              className="section whitespace-nowrap"
              style={{ "--speed": `${20000}ms` }}
            >
              {cryptoData.map((token, i) => (
                <div
                  key={i}
                  className=" items-center inline-block !w-[200px] md:!w-[285px]"
                >
                  <span className="mr-2 md:mr-4 text-[#313033] font-montserrat text-base">
                    {token.baseToken}
                  </span>
                  <span className="text-[#1A73E9] mr-2 md:mr-4 font-montserrat text-base">
                    ${token.price}
                  </span>
                  {token.changePercentage >= 0 ? (
                    <ArrowDropUpIcon
                      className="text-[#4C9C18] text-[24px]"
                      title="Increase"
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className="text-red-600 text-[24px]"
                      title="Decrease"
                    />
                  )}
                  {token.changePercentage >= 0 ? (
                    <span className="text-[#4C9C18] font-montserrat text-base mr-2 md:mr-4">
                      {token.changePercentage}%
                    </span>
                  ) : token.changePercentage < 0 ? (
                    <span className="text-red-600 font-montserrat mr-4">
                      {token.changePercentage}%
                    </span>
                  ) : (
                    <span className="text-[#757575] font-montserrat mr-4">
                      {token.changePercentage}%
                    </span>
                  )}
                </div>
              ))}
            </section>
            <section
              className="section whitespace-nowrap"
              style={{ "--speed": `${20000}ms` }}
            >
              {cryptoData.map((token, i) => (
                <div
                  key={i}
                  className=" items-center inline-block !w-[200px] md:!w-[285px]"
                >
                  <span className="mr-2 md:mr-4 text-[#313033] font-montserrat text-base">
                    {token.baseToken}
                  </span>
                  <span className="text-[#1A73E9] mr-2 md:mr-4 font-montserrat text-base">
                    ${token.price}
                  </span>
                  {token.changePercentage >= 0 ? (
                    <ArrowDropUpIcon
                      className="text-[#4C9C18] text-[24px]"
                      title="Increase"
                    />
                  ) : (
                    <ArrowDropDownIcon
                      className="text-red-600 text-[24px]"
                      title="Decrease"
                    />
                  )}
                  {token.changePercentage >= 0 ? (
                    <span className="text-[#4C9C18] font-montserrat text-base mr-2 md:mr-4">
                      {token.changePercentage}%
                    </span>
                  ) : token.changePercentage < 0 ? (
                    <span className="text-red-600 font-montserrat text-base mr-2 md:mr-4">
                      {token.changePercentage}%
                    </span>
                  ) : (
                    <span className="text-[#757575] font-montserrat text-base mr-2 md:mr-4">
                      {token.changePercentage}%
                    </span>
                  )}
                </div>
              ))}
            </section>
          </div>
        ) : (
          <p className="text-center text-red-500 mr-2 md:mr-4 flex items-center font-montserrat text-base px-4">
            Failed to fetch commodity tokens data
            <ReplayIcon
              className="text-[#1A73E9] cursor-pointer ml-2"
              onClick={() => {
                setLoading(true);
                fetchCryptoData();
              }}
            />
          </p>
        )}
      </div>
    </div>
  );
};
