import { Divider } from "@mui/material";
import Link from "next/link";
import { useRef, useState } from "react";
import { MenuDrawer } from "../../components/Drawer/CoimexMenu";
import { Menu } from "@headlessui/react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { Disclosure } from "@headlessui/react";
import React from "react";
import LivePricing from "../../components/Box/LivePricingData";
import { LivePrice } from "../../components/Box/LivePricing";
export function Header(props) {
  const menuDrawer = useRef();
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [clickedItem, setClickedItem] = useState(null);

  const links = [
    { id: "1", href: "/pricing?package=importer", label: t("Header.9") },
    { id: "2", href: "/pricing?package=exporter", label: t("Header.10") },
    { id: "4", href: "/pricing?package=Both", label: t("Header.11") },
  ];
  const linksPlatform = [
    { id: "1", href: "/importer-platform", label: t("Header.8") },
    { id: "2", href: "/exporter-platform", label: t("Header.7") },
  ];

  const blockChain = [
    { id: "1", href: "/escrow-safe-payment", label: t("Header.16") },
    { id: "2", href: "/prompt-pay-assurance", label: t("Header.17") },
    { id: "3", href: "/commodity-token-marketplace", label: t("Header.18") },
    { id: "4", href: "/future-blockchain-solutions", label: t("Header.15") },
  ];

  async function handleLang(key) {
    i18n.changeLanguage(key);
    router.push(router.asPath, undefined, { locale: key });
    localStorage.setItem("lang", key);
  }

  const { asPath } = useRouter();

  return (
    <Menu>
      <div className={"bg-PrimaryA "}>
        {asPath !== "/waiting-list" && (
          <>
            <div className="hidden  h-[55px] md:flex justify-between m-auto items-center w-11/12 px-1 pl-5">
              <Link href={"/"}>
                <div>
                  <img
                    loading="lazy"
                    alt="Coimex"
                    width="100%"
                    height="100%"
                    className="cursor-pointer "
                    src="/images/logoSvg.svg"
                  />
                </div>
              </Link>
              <Link href={"https://app.coimex.co"}>
                <p className="font-montserrat font-semibold select-none text-[#38466D] cursor-pointer hidden md:flex leading-6 text-base">
                  {t("Header.0")}
                </p>
              </Link>
              <MenuSvg
                onClick={() => menuDrawer.current.openSheet()}
                from={router.route}
              />
            </div>

            <div
              className={`${
                router.route === "/"
                  ? "absolute bg-white/5 backdrop-blur-sm bg-opacity-50"
                  : router.route === "/token-sale"
                  ? "absolute bg-white/10"
                  : ""
              } h-[55px] flex justify-between m-auto items-center w-11/12 px-1 pl-5 md:hidden `}
            >
              <Link href={"/"}>
                <div>
                  <img
                    loading="lazy"
                    alt="Coimex"
                    width="100px"
                    height="100%"
                    className="cursor-pointer "
                    src={
                      router.route === "/"
                        ? "/images/coimex_whitelogo_1.png"
                        : "/images/logoSvg.svg"
                    }
                  />
                </div>
              </Link>
              <Link href={"https://app.coimex.co"}>
                <p className="font-montserrat font-semibold select-none text-[#38466D] cursor-pointer hidden md:flex leading-6 text-base">
                  {t("Header.0")}
                </p>
              </Link>
              <MenuSvg
                onClick={() => menuDrawer.current.openSheet()}
                from={router.route}
              />
            </div>
          </>
        )}

        {router.route === "/" && (
          <div className="md:block hidden">
            <LivePrice />
          </div>
        )}

        <div
          className={`${
            router.route === "/"
              ? "absolute bg-white/5 backdrop-blur-sm bg-opacity-50"
              : router.route === "/token-sale"
              ? "absolute bg-white/10"
              : "bg-PrimaryB"
          } w-full backdrop-blur-sm`}
        >
          <div className="hidden md:flex w-11/12 m-auto justify-between items-center">
            <div className="px-3 flex items-center">
              <Disclosure>
                <Menu>
                  <Menu.Button
                    onMouseEnter={() => setHoveredItem("platform")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("platform")}
                    className={`py-3 ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "platform"
                        ? "font-bold font-MontserratBold scale-110 duration-0"
                        : hoveredItem === "platform"
                        ? "font-bold font-MontserratBold scale-110 duration-300"
                        : "font-MontserratRegular scale-100 duration-300"
                    }`}
                  >
                    {t("Header.1")}
                  </Menu.Button>
                  <Menu.Items className="h-24 w-64 absolute rounded-md justify-around p-4 flex flex-col self-start mt-12 bg-[#D9D9D91A] z-30">
                    {linksPlatform.map((link) => (
                      <Menu.Item key={link.href + link.id}>
                        {({ active }) => (
                          <Link key={link.id} href={link.href}>
                            <a {...props}>
                              <span className="text-textColor cursor-pointer text-base font-MontserratRegular hover:font-MontserratSemiBold">
                                {link.label}
                              </span>
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </Disclosure>

              <Disclosure>
                <Menu>
                  <Menu.Button
                    onMouseEnter={() => setHoveredItem("blockchain")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("blockchain")}
                    className={`py-3 ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "blockchain"
                        ? "font-bold font-MontserratBold scale-110 duration-0"
                        : hoveredItem === "blockchain"
                        ? "font-bold font-MontserratBold scale-110 duration-300"
                        : "font-MontserratRegular scale-100 duration-300"
                    }`}
                  >
                    {t("Header.12")}
                  </Menu.Button>
                  <Menu.Items className="h-52 w-[290px] absolute ml-[125px] rounded-md justify-around p-4 flex flex-col self-start mt-12 bg-[#8791A7] z-30">
                    {blockChain.map((link) => (
                      <Menu.Item key={link.href + link.id}>
                        {({ active }) => (
                          <Link href={link.href}>
                            <a {...props} key={link.id}>
                              <span className="text-textColor cursor-pointer text-base font-MontserratRegular hover:font-MontserratSemiBold">
                                {link.label}
                              </span>
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </Disclosure>

              <Disclosure>
                <Menu>
                  <Menu.Button
                    onMouseEnter={() => setHoveredItem("pricing")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("pricing")}
                    className={`py-3 ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "pricing"
                        ? "font-bold font-MontserratBold scale-110 duration-0"
                        : hoveredItem === "pricing"
                        ? "font-bold font-MontserratBold scale-110 duration-300"
                        : "font-MontserratRegular scale-100 duration-300"
                    }`}
                  >
                    {t("Header.2")}
                  </Menu.Button>
                  <Menu.Items className="h-32 w-64 ml-[250px] absolute rounded-md justify-around p-4 flex flex-col self-start mt-12 bg-[#8791A7] z-30">
                    {links.map((link) => (
                      <Menu.Item key={link.href + link.id}>
                        {({ active }) => (
                          <Link key={link.id} href={link.href}>
                            <a {...props}>
                              <span className="text-textColor text-base cursor-pointer font-MontserratRegular hover:font-MontserratSemiBold">
                                {link.label}
                              </span>
                            </a>
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Menu>
              </Disclosure>

              <Link href={"/industries"}>
                <a>
                  <p
                    onMouseEnter={() => setHoveredItem("industries")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("industries")}
                    className={`py-3 ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "industries"
                        ? "font-bold font-MontserratBold scale-110 duration-0"
                        : hoveredItem === "industries"
                        ? "font-bold font-MontserratBold scale-110 duration-300"
                        : "font-MontserratRegular scale-100 duration-300"
                    }`}
                  >
                    {t("Header.3")}
                  </p>
                </a>
              </Link>

              <Link href={"/trust"}>
                <a>
                  <p
                    onMouseEnter={() => setHoveredItem("trust")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("trust")}
                    className={`py-3 ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "trust"
                        ? "font-bold font-MontserratBold scale-110 duration-0"
                        : hoveredItem === "trust"
                        ? "font-bold font-MontserratBold scale-110 duration-300"
                        : "font-MontserratRegular scale-100 duration-300"
                    }`}
                  >
                    {t("Header.4")}
                  </p>
                </a>
              </Link>

              <a
                href={`https://eximtoken.co/${
                  router.locale === "tr" ? "/tr" : ""
                }`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <p
                  onMouseEnter={() => setHoveredItem("exim")}
                  onMouseLeave={() => setHoveredItem(null)}
                  onClick={() => setClickedItem("exim")}
                  className={`py-3 ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                    clickedItem === "exim"
                      ? "font-bold font-MontserratBold scale-110 duration-0"
                      : hoveredItem === "exim"
                      ? "font-bold font-MontserratBold scale-110 duration-300"
                      : "font-MontserratRegular scale-100 duration-300"
                  }`}
                >
                  {t("Header.14")}
                </p>
              </a>

              <a
                href={`https://coimex.co/blog${
                  router.locale === "tr" ? "/tr" : ""
                }`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <p
                  onMouseEnter={() => setHoveredItem("blog")}
                  onMouseLeave={() => setHoveredItem(null)}
                  onClick={() => setClickedItem("blog")}
                  className={`py-3 ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                    clickedItem === "blog"
                      ? "font-bold font-MontserratBold scale-110 duration-0"
                      : hoveredItem === "blog"
                      ? "font-bold font-MontserratBold scale-110 duration-300"
                      : "font-MontserratRegular scale-100 duration-300"
                  }`}
                >
                  {t("Header.5")}
                </p>
              </a>

              <Link href={"/about-us"}>
                <a>
                  <p
                    onMouseEnter={() => setHoveredItem("about")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={() => setClickedItem("about")}
                    className={`py-3 ml-12 text-textColor hidden md:flex leading-6 text-base select-none cursor-pointer transition ease-out transform ${
                      clickedItem === "about"
                        ? "font-bold font-MontserratBold scale-110 duration-0"
                        : hoveredItem === "about"
                        ? "font-bold font-MontserratBold scale-110 duration-300"
                        : "font-MontserratRegular scale-100 duration-300"
                    }`}
                  >
                    {t("Header.6")}
                  </p>
                </a>
              </Link>
            </div>
            <div className="flex items-center">
              <p
                onClick={() => handleLang("en")}
                className={`${
                  i18n.language == "en"
                    ? "font-montserrat font-bold"
                    : "font-montserrat"
                } text-textColor hidden md:flex leading-6 text-lg select-none cursor-pointer pl-1`}
              >
                English
              </p>
              <Divider
                orientation="vertical"
                variant="middle"
                className="w-[2px] m-3 h-6 bg-textColor"
              />
              <p
                onClick={() => handleLang("tr")}
                className={`${
                  i18n.language == "tr"
                    ? "font-montserrat font-bold"
                    : "font-montserrat"
                } text-textColor hidden md:flex leading-6 text-lg select-none cursor-pointer pl-1`}
              >
                Türkçe
              </p>
            </div>
          </div>
        </div>

        <MenuDrawer
          blockChain={blockChain}
          linksPlatform={linksPlatform}
          links={links}
          ref={menuDrawer}
        />
      </div>
    </Menu>
  );
}

const MenuSvg = ({ onClick, from }) => (
  <svg
    onClick={onClick}
    className="cursor-pointer z-10 md:hidden "
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    width="35"
    fill={from === "/" ? "#fff" : "#38466D"}
  >
    <path d="M6 36v-3h36v3Zm0-10.5v-3h36v3ZM6 15v-3h36v3Z" />
  </svg>
);
